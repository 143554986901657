import React, { useEffect } from "react";
import './style.scss';
import { useLocation } from 'react-router-dom';

function Mobile() {
  useEffect(() => {
    document.getElementById("datalayer_js")?.remove();
    //document.getElementById("spa_js")?.remove();
    document.getElementById("integration_js")?.remove();
  }, []);

  const location = useLocation();

  // Check the previous URL and configure iframe src accordingly
  const iframeSrc = location.state?.from === '/view/epp' ? "/view/epp" : "/";

  return (
    <div className='frame_main_container'>
      <div className='frame_container' style={{ width: "395px" }}>
        <iframe src={iframeSrc} width="395" height="710"></iframe>
      </div>
    </div>
  );
}

export default Mobile;
