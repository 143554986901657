import axios from '../../../../utils/axios-interceptor';
import { getAPIHost } from '../../../../utils/helper';
import { getAccessHeader, getADA } from '../../../../utils/loginSession';
export const enums = {
  SET_EPP_PRIVILEGE_STATE: {
    type: 'SET_EPP_PRIVILEGE_STATE',
    state: 'eppPrivilege'
  },
  SET_RESTRICTION_DETAIL_STATE: {
    type: 'SET_RESTRICTION_DETAIL_STATE',
    state: 'restrictionDetails',
  },
};

// COMMON FETCH & SET STATE FUNCTIONS -->
export const setState = (type, state) => {
  return {
    type: type.type,
    payload: state,
  };
};

export const fetchBlockPrivilege = params => async (dispatch, getState) => {
  try {
    const apiUrl = getAPIHost();
    let res = await axios({
      headers: getAccessHeader(getState()),
      method: 'GET',
      url: `${apiUrl}/epp/fetchBlockPrivilegeWithApplicationStatus`,
      params: {
        aff: params.aff,
        loginAda: getADA(),
      },
      timeout: 60000,
    });


    if (res?.data) {
      dispatch(setState(enums.SET_EPP_PRIVILEGE_STATE, { ...res.data }));
      return { ...res.data };
    } 
  } catch (err) {
    console.log(err);
    // if (err?.code === 'ECONNABORTED') {
    //   return { status: "timeout" };
    // }

    throw err;
  }
};

export const fetchRestrictionRelaxation = params => async (dispatch, getState) => {
  try {
    const apiUrl = getAPIHost();
    let res = await axios({
      headers: getAccessHeader(getState()),
      method: 'GET',
      url: `${apiUrl}/epp/getRestrictionRelaxation`,
      params: {
        aff: params.aff,
        country: params.country,
        language: params.language,
        loginAda: getADA(),
      },
      timeout: 60000,
    });


    if (res?.data) {
      dispatch(setState(enums.SET_RESTRICTION_DETAIL_STATE, res.data));
      return res.data;
    } 
  } catch (err) {
    console.log(err);
    // if (err?.code === 'ECONNABORTED') {
    //   return { status: "timeout" };
    // }

    throw err;
  }
};

export const getPDF = () => async (dispatch, getState) => {
  try {
    const market = localStorage.getItem('country')
    const apiUrl = getAPIHost();
    const queryParams = `aboNo=${getADA()}&market=${market}`;
    const res = await axios({
      headers: getAccessHeader(getState()),
      method: 'GET',
      url: `${apiUrl}/epp/getTncPDF?${queryParams}`,
    });

    return {
      ...res.data,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const requestOtp = data => async (dispatch, getState) => {
  try {
    const apiUrl = getAPIHost();
    const res = await axios({
      headers: getAccessHeader(getState()),
      method: 'POST',
      url: `${apiUrl}/epp/requestOtp`,
      data: data,
    });

    return {
      ...res.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const verifyOtp = data => async (dispatch, getState) => {
  try {
    const apiUrl = getAPIHost();
    const res = await axios({
      headers: getAccessHeader(getState()),
      method: 'POST',
      url: `${apiUrl}/epp/verifyOtp`,
      data: data,
    });

    return {
      ...res.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const applyEpp = data => async (dispatch, getState) => {
  try {
    const apiUrl = getAPIHost();
    const res = await axios({
      headers: getAccessHeader(getState()),
      method: 'POST',
      url: `${apiUrl}/epp/applyEpp`,
      data: data,
    });

    return res.data;

  } catch (err) {
    console.log(err);
  }
};

export const applyQuotaIncrease = data => async (dispatch, getState) => {
  try {
    const apiUrl = getAPIHost();
    const res = await axios({
      headers: getAccessHeader(getState()),
      method: 'POST',
      url: `${apiUrl}/epp/applyQuotaIncrease`,
      data: data,
    });

    return res.data;

  } catch (err) {
    console.log(err);
  }
};

export const readNotification = data => async (dispatch, getState) => {
  try {
    const apiUrl = getAPIHost();
    const res = await axios({
      headers: getAccessHeader(getState()),
      method: 'POST',
      url: `${apiUrl}/epp/readNotification`,
      data: data,
    });

    return {
      ...res.data,
    };
  } catch (err) {
    console.log(err);
  }
};

// state
const eppPrivilegeState = (state = {}, action) => {

  switch (action.type) {
    // for special case
    
    // for use enum
    default:
      if (enums[action.type]) {
        return {
          ...state,
          [enums[action.type].state]: action.payload,
        };
      }

    // for default
    return state;
  }
};

export default {
  eppPrivilegeState,
};
